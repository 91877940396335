import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';

export default function ViewStudentsData() {
  const [ data, setData ] = useState('');
  const axiosPrivate = useAxiosPrivate();

  const customStyles = {
    headCells: {
      style: {
        fontSize: '20px'
      },
    },
    cells: {
      style: {
        fontSize: '15px'
      },
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosPrivate.get('/verify');
        if (response.status === 200) {
          setData(response.data)
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  },[])

  const columns = [
    {
      name: 'Username',
      selector: row => row.username,
      sortable: true
    },
    {
      name: 'Name',
      selector: row => row.name,
      sortable: true
    },
    {
      name: 'Email',
      selector: row => row.email,
      sortable: true
    },
    {
      name: 'Phone',
      selector: row => row.phone,
      sortable: true
    },
    {
      name: 'Qualification',
      selector: row => row.qualification,
      sortable: true
    },
    {
      name: 'Designation',
      selector: row => row.designation,
      sortable: true
    },
    {
      name: 'Verify',
      cell:(row) => {
        return <button 
          onClick={()=> { axiosPrivate.post('/verify',row).then( axiosPrivate.delete(`/verify/${row.username}`) ) } }
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:scale-105">
            Verify
        </button>
      }
    },
    {
      name: 'Delete',
      cell:(row) => {
        return <button onClick={()=>axiosPrivate.delete(`/verify/${row.username}`)} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:scale-105">
                Delete
              </button>
      }
    }
  ];

  return (
    <div className="container mx-auto p-4">
      <div className="flex flex-row justify-between mb-6">
        <h1 className="text-3xl font-bold ">Verify Registered Teacher's</h1>
        <Link
          to={'/admin-dashboard'}
          className="inline-block bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md shadow-md transition duration-300 ease-in-out"
        >
          Back to dashboard
        </Link>
      </div>
      <div className="overflow-x-auto">
        <DataTable
          columns={columns}
          data={data}
          pagination
          paginationPerPage={10}
          paginationRowsPerPageOptions={[10, 50, 100]}
          customStyles={customStyles}
          highlightOnHover
        />
      </div>
    </div>  
  );
}
