import axios from 'axios';
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import InputField from "./Admin/components/InputField"
import { ToastContainer, toast } from 'react-toastify';
import { HiEye, HiEyeOff } from "react-icons/hi";

export default function SignUp() {
  const [ username, setUsername] = useState("");
  const [ name, setName] = useState("");
  const [ email, setEmail] = useState("");
  const [ phone, setPhone] = useState("");
  const [ qualification, setQualification] = useState("");
  const [ designation, setDesignation ] = useState("");
  const [ password, setPassword ] = useState("");
  const [ confirmPassword, setConfirmPassword ] = useState("");
  const [ showPassword, setShowPassword ] = useState(false);
  const navigate = useNavigate();

  const formData = {
    username: username,
    name:name,
    email:email,
    phone:phone,
    qualification:qualification,
    designation:designation,
    password:password
  };
  const handleSignUp = async (e) => {
    e.preventDefault();
    axios({
      method: "post",
      url: 'http://localhost:3030/register',
      data: formData,
    })
    .then((response)=>{
      if(response.status === 200){
        navigate("/thankyou");
      }
    })
  };

  const passwordInputType = showPassword ? "text" : "password";
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div className="min-h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <h2 className="mb-5 text-center text-3xl font-extrabold text-gray-900">
              Sign Up
            </h2>
            <form className="space-y-6" onSubmit={handleSignUp}>
              <div className="flex flex-col">
                <div>
                  <div>
                    <InputField
                      label="Username"
                      labelClassName="block text-xl font-medium text-gray-700"
                      divClassName="mt-1"
                      id="username"
                      name="username"
                      type="text"
                      value={username}
                      onBlur={(e)=>{console.log('hello')}}
                      onChange={(e) => setUsername(e.target.value)}
                      inputClassName="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      placeholder="Type your username"
                      required={true}
                    />
                  </div>

                  <div>
                    <InputField
                      label="Name"
                      labelClassName="mt-2 block text-xl font-medium text-gray-700"
                      divClassName="mt-1"
                      id="name"
                      name="name"
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      inputClassName="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      placeholder="Type your name"
                      required={true}
                    />
                  </div>

                  <div>
                    <InputField
                      label="Email"
                      labelClassName="mt-2 block text-xl font-medium text-gray-700"
                      divClassName="mt-1"
                      id="email"
                      name="email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      inputClassName="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      placeholder="Type your email"
                      required={true}
                    />
                  </div>

                  <div>
                    <label
                        htmlFor="phone"
                        className="mt-2 block text-xl font-medium text-gray-700"
                    >
                    Phone <span className="text-red-500">*</span>
                    </label>
                    <input
                      id="phone"
                      name="phone"
                      type="number"
                      min="1000000000"
                      max="9999999999"
                      inputMode="numeric"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      placeholder="Enter 10-digit number"
                      required
                    />
                  </div>
                </div>

                <div>
                  <div>
                    <InputField
                      label="Highest Qualification"
                      labelClassName="mt-2 block text-xl font-medium text-gray-700"
                      divClassName="mt-1"
                      id="qualification"
                      name="qualification"
                      type="text"
                      value={qualification}
                      onChange={(e) => setQualification(e.target.value)}
                      inputClassName="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      placeholder="Type your highest qualification"
                      required={true}
                    />
                  </div>

                  <div>
                    <InputField
                      label="Designation"
                      labelClassName="mt-2 block text-xl font-medium text-gray-700"
                      divClassName="mt-1"
                      id="designation"
                      name="designation"
                      type="text"
                      value={designation}
                      onChange={(e) => setDesignation(e.target.value)}
                      inputClassName="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      placeholder="Type your highest designation"
                      required={true}
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="password"
                      className="mt-2 block text-xl font-medium text-gray-700"
                    >
                      Password <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <input
                        id="password"
                        name="password"
                        type={passwordInputType}
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm pr-10"
                        placeholder="Type Your Password"
                      />
                      <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                        {showPassword ? (
                          <HiEyeOff
                            className="h-5 w-5 text-gray-400 cursor-pointer"
                            onClick={handleTogglePassword}
                          />
                        ) : (
                          <HiEye
                            className="h-5 w-5 text-gray-400 cursor-pointer"
                            onClick={handleTogglePassword}
                          />
                        )}
                      </span>
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="confirmpassword"
                      className="mt-2 block text-xl font-medium text-gray-700"
                    >
                      Confirm Password <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <input
                        id="confirmpassword"
                        name="confirmpassword"
                        type={passwordInputType}
                        autoComplete="current-password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm pr-10"
                        placeholder="Confirm Your Password"
                      />
                      <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                        {showPassword ? (
                          <HiEyeOff
                            className="h-5 w-5 text-gray-400 cursor-pointer"
                            onClick={handleTogglePassword}
                          />
                        ) : (
                          <HiEye
                            className="h-5 w-5 text-gray-400 cursor-pointer"
                            onClick={handleTogglePassword}
                          />
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>            

              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-l font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-300 ease-in-out transform hover:scale-105"
                >
                  Sign Up
                </button>
                <div className="mt-4 flex items-center justify-center text-l animate-bounce">
                  <Link
                    to="/signin"
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Already have an account? Signin
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

