import React, { useState } from 'react';
import FileUpload from '../../../components/FileUpload';

export default function LoadData() {
  return (
    <>
      <div>
        <FileUpload url={'/subject'} heading={"Upload Subject's List"} />
      </div>
      <div>
        <FileUpload url={'/student'} heading={"Upload Student's List"} />
      </div>
    </>
  );
}
