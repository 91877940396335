import EditModal from './EditModal';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import ConfirmAlert from '../../../components/ConfirmAlert';

export default function ViewStudentsData() {

  const [ data, setData ] = useState('');
  const [isModalOpen, setIsModalOpen ] = useState(false);
  const [ isConfirmOpen, setIsConfirmOpen ] = useState(false);
  const [selectedStudent, setSelectedStudent ] = useState(null);
  const [filter, setFilter] = useState({
    name: '',
    roll: '',
    guardian: '',
    email: '',
    sex: ''
  });
  const [message, setMessage ] = useState('');
  const axiosPrivate = useAxiosPrivate();

  const customStyles = {
    headCells: {
      style: {
        fontSize: '20px'
      },
    },
    cells: {
      style: {
        fontSize: '15px'
      },
    },
  };
  
  const filteredData = () => {
    let modifyData = [ ...data ];
    modifyData = modifyData.filter(student => student.student_name.toLowerCase().includes(filter.name.toLowerCase()));
    modifyData = modifyData.filter(student => student.guardian_name.toLowerCase().includes(filter.guardian.toLowerCase()));
    modifyData = modifyData.filter(student => student.student_email.toLowerCase().includes(filter.email.toLowerCase()));
    modifyData = modifyData.filter(student => student.sex.toLowerCase().includes(filter.sex.toLowerCase()));
    // modifyData = modifyData.filter(student => filter.roll &&  student.makaut_roll.includes(filter.roll));
    return modifyData;
  }

  useEffect(()=> {
    const fetchData = async () => {
      try {
        const response = await axiosPrivate.get('/student');
        if (response.status === 200) {
          setData(response.data)
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  },[message])

  const handleEditClick = (student) => {
    setSelectedStudent(student);
    setIsModalOpen(true);
  };

  const handleDeleteClick = (student) => {
    setSelectedStudent(student);
    setIsConfirmOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedStudent(null);
  };
  
  const handleCloseConfirm = () => {
    setIsConfirmOpen(false);
    setSelectedStudent(null);
  };
  
  const handleConfirm = async() => {
    try {
      const response = await axiosPrivate.delete(`/student/${selectedStudent.makaut_roll}`);
      if (response.status === 200) {
        setMessage(response.data.message);
      }
      handleCloseConfirm();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const columns = [
    {
      name: 'MAKAUT Roll',
      selector: row => row.makaut_roll,
      sortable:true
    },
    {
      name: 'Student Name',
      selector: row => row.student_name,
      sortable:true
    },
    {
      name: 'Sex',
      selector: row => row.sex,
      sortable:true
    },
    {
      name: 'Guardian Name',
      selector: row => row.guardian_name,
    },
    {
      name: 'Guardian Mobile',
      selector: row => row.guardian_mobile,
    },
    {
      name: 'Student Mobile',
      selector: row => row.student_mobile,
    },
    {
      name: 'Student Email',
      selector: row => row.student_email,
    },
    {
      name: 'Edit',
      cell:(row)=>{
        return <button onClick={() => handleEditClick(row)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:scale-105">
                Edit
              </button>
      },
    },
    {
      name: 'Delete',
      cell:(row)=>{
        return <button onClick={() => handleDeleteClick(row)} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:scale-105">
                Delete
              </button>
      },
    }
  ];
  

  return (
    <div className="container mt-5 mx-auto p-4">
      <div className="flex flex-row justify-between mb-6">
        <h1 className="text-3xl font-bold ">Student Data</h1>
        <Link
          to={'/admin-dashboard'}
          className="inline-block bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md shadow-md transition duration-300 ease-in-out"
        >
          Back to dashboard
        </Link>
      </div>
      <div className="overflow-x-auto">
        <div className="flex flex-wrap items-center mb-4">
          {/* Search by Student Name */}
          <div className="flex items-center mr-4 mb-2">
            <input
              type="text"
              name="name"
              value={filter.name}
              onChange={(e) => setFilter(prevFilter => ({ ...prevFilter, name: e.target.value }))}
              placeholder="Search by name..."
              className="border p-2 rounded-md focus:outline-none focus:border-blue-500"
            />
          </div>

          {/* Search by MAKAUT Roll */}
          <div className="flex items-center mr-4 mb-2">
            <input
              type="text"
              name="roll"
              value={filter.roll}
              onChange={(e) => setFilter(prevFilter => ({ ...prevFilter, roll: e.target.value }))}
              placeholder="Search by roll..."
              className="border p-2 rounded-md focus:outline-none focus:border-blue-500"
            />
          </div>

          {/* Search by Guardian Name */}
          <div className="flex items-center mr-4 mb-2">
            <input
              type="text"
              name="guardian"
              value={filter.guardian}
              onChange={(e) => setFilter(prevFilter => ({ ...prevFilter, guardian: e.target.value }))}
              placeholder="Search by guardian name..."
              className="border p-2 rounded-md focus:outline-none focus:border-blue-500"
            />
          </div>

          {/* Search by Student Email */}
          <div className="flex items-center mr-4 mb-2">
            <input
              type="text"
              name="email"
              value={filter.email}
              onChange={(e) => setFilter(prevFilter => ({ ...prevFilter, email: e.target.value }))}
              placeholder="Search by email..."
              className="border p-2 rounded-md focus:outline-none focus:border-blue-500"
            />
          </div>

          {/* Filter by Sex */}
          <div className="flex items-center mb-2">
            <div className="relative">
              <select
                name="sex"
                value={filter.sex}
                onChange={(e) => setFilter(prevFilter => ({ ...prevFilter, sex: e.target.value }))}
                className="border p-2 rounded-md appearance-none bg-white focus:outline-none focus:border-blue-500"
              >
                <option value="">Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </div>

        </div>
        <DataTable
          className="dataTable text-xl"
          columns={columns}
          data={filteredData()}
          pagination
          paginationPerPage={10}
          paginationRowsPerPageOptions={[10, 50, 100]}
          customStyles={customStyles}
          highlightOnHover
        />
      </div>
      {
        isModalOpen && <EditModal onClose={handleCloseModal} studentData={selectedStudent} setMessage={setMessage} handleCloseModal={handleCloseModal} />
      }
      {
        isConfirmOpen && <ConfirmAlert message={`Do you want to delete the student <br> ${selectedStudent.student_name}`} onClose={handleCloseConfirm} onConfirm={handleConfirm} />
      }
      {
        message !== '' && 
        <div className="fixed top-0 right-0 mr-4 mt-4 bg-green-500 text-xl text-white py-2 px-4 rounded-md shadow-md z-50">
        {message}
        </div>
      }
    </div>
  );
}
