import EditModal from './EditModal';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import ConfirmAlert from '../../../components/ConfirmAlert';

export default function ViewSubjectData() {

  const [ data, setData ] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ isConfirmOpen, setIsConfirmOpen ] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [message, setMessage] = useState('');
  const axiosPrivate = useAxiosPrivate();

  const customStyles = {
    headCells: {
      style: {
        fontSize: '20px'
      },
    },
    cells: {
      style: {
        fontSize: '15px'
      },
    },
  };

  useEffect(()=> {
    const fetchData = async () => {
      try {
        const response = await axiosPrivate.get('/subject');
        if (response.status === 200) {
          setData(response.data)
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  },[message])

  const handleEditClick = (subject) => {
    setSelectedSubject(subject);
    setIsModalOpen(true);
  };

  const handleDeleteClick = (subject) => {
    setSelectedSubject(subject);
    setIsConfirmOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedSubject(null);
  };
  
  const handleCloseConfirm = () => {
    setIsConfirmOpen(false);
    setSelectedSubject(null);
  };
  const handleConfirm = async() => {
    try {
      const response = await axiosPrivate.delete(`/subject/${selectedSubject.code}`);
      if (response.status === 200) {
        setMessage(response.data.message);
      }
      handleCloseConfirm();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const columns = [
    {
      name: 'Subject Code',
      selector: row => row.code,
      sortable:true
    },
    {
      name: 'Subject Name',
      selector: row => row.name,
      sortable:true
    },
    {
      name: 'Semester',
      selector: row => row.sem,
      sortable:true
    },
    {
      name: 'Year',
      selector: row => row.year,
    },
    {
      name: 'Department',
      selector: row => row.department,
    },
    {
      name: 'Edit',
      cell:(row)=>{
        return <button onClick={() => handleEditClick(row)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:scale-105">
                Edit
              </button>
      },
    },
    {
      name: 'Delete',
      cell:(row)=>{
        return <button onClick={() => handleDeleteClick(row)} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:scale-105">
                Delete
              </button>
      },
    }
  ];
  

  return (
    <div className="container mx-auto p-4">
      <div className="flex flex-row justify-between mb-6">
        <h1 className="text-3xl font-bold ">Subject Data</h1>
        <Link
          to={'/admin-dashboard'}
          className="inline-block bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md shadow-md transition duration-300 ease-in-out"
        >
          Back to dashboard
        </Link>
      </div>
      <div className="overflow-x-auto">
        <DataTable
          columns={columns}
          data={data}
          pagination
          paginationPerPage={10}
          paginationRowsPerPageOptions={[10, 50, 100]}
          customStyles={customStyles}
          highlightOnHover
        />
      </div>
      {
        isModalOpen && <EditModal onClose={handleCloseModal} subjectData={selectedSubject} setMessage={setMessage} handleCloseModal={handleCloseModal} />
      }
      {
        isConfirmOpen && <ConfirmAlert message={`Do you want to delete the student <br> ${selectedSubject.student_name}`} studentData={selectedSubject} onClose={handleCloseConfirm} onConfirm={handleConfirm} />
      }
      {
        message !== '' && 
        <div className="fixed top-0 right-0 mr-4 mt-4 bg-green-500 text-xl text-white py-2 px-4 rounded-md shadow-md z-50">
        {message}
        </div>
      }
    </div>  
  );
}
