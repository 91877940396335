// App.js
import React,{ useContext } from "react";
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Routes, Route, Navigate } from "react-router-dom";
import AuthContext from "./context/AuthProvider";
import Home from "./pages/Home";
import SignIn from "./pages/SignIn";
import Signup from "./pages/SignUp";
import Admin from "./pages/Admin/AdminLogin";
import AdminDashboard from "./pages/Admin/AdminDasboard";
import VerifyRegistration from './pages/Admin/VerifyRegistration/VerifyRegistration';
import ViewTeachersData from './pages/Admin/ViewTeachersData/ViewTeachersData';
import ViewStudentsData from './pages/Admin/ViewStudentsData/ViewStudentsData';
import LoadData from './pages/Admin/LoadData/LoadData';
import TeacherDashboard from "./pages/Teacher/TeacherDashboard";
import PageNotFound from "./pages/PageNotFound"
import RequireAuth from './components/RequireAuth';
import PersistLogin from "./components/PersistLogin";
import Routine from "./pages/Teacher/Routine/Routine";
import ManageSession from "./pages/Admin/ManageSection/ManageSection";
import ThankYouPage from "./pages/Thanyou/ThankYouPage";
import ViewSubjectData from "./pages/Admin/ViewSubjectData/ViewSubjectData";

const ROLES = {
  'Teacher': 2001,
  'Student': 1984,
  'Admin': 5150
}

export default function App() {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <Routes>
      <Route path="/" element={<Home/>} />
      {/* Public routes */}
      <Route path="/signin" element={isAuthenticated ? <Navigate to="/teacher-dashboard" /> : <SignIn />} />
      <Route path="/signup" element={<Signup/>} />
      <Route path="/admin" element={isAuthenticated ? <Navigate to="/admin-dashboard" /> : <Admin/> } />
      <Route path="/thankyou" element={<ThankYouPage/>}/>
      {/* Protected routes */}
      <Route element={<PersistLogin/>}>
        <Route path="/admin-dashboard" element={<RequireAuth allowedRoles={[ROLES.Admin]} />} >
          <Route index={true} element={<AdminDashboard/>}/>
          <Route path="/admin-dashboard/verify-registration" element={<VerifyRegistration/>}/>
          <Route path="/admin-dashboard/view-teachers-data" element={<ViewTeachersData/>}/>
          <Route path="/admin-dashboard/view-students-data" element={<ViewStudentsData/>}/>
          <Route path="/admin-dashboard/view-subjects-data" element={<ViewSubjectData/>}/>
          <Route path="/admin-dashboard/load-data" element={<LoadData/>}/>
          <Route path="/admin-dashboard/manage-sections" element={<ManageSession/>}/>
        </Route>
        <Route path="/teacher-dashboard" element={<RequireAuth allowedRoles={[ROLES.Admin,ROLES.Teacher]} />}>
          <Route index={true} element={<TeacherDashboard/>} />
          <Route path="/teacher-dashboard/routine" element={<Routine/>} />
        </Route>
      </Route>
      {/* Default route for handling 404 errors */}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}
