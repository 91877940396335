import React,{ useState } from 'react';
import InputField from '../components/InputField';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';

const EditModal = ({ teacherData , setMessage, handleCloseModal }) => {
  const [ data, setData ] = useState(teacherData);
  const axiosPrivate = useAxiosPrivate();
  const handleUpdate = async (e) => {
    e.preventDefault();
    if(!objectsAreEqual(data,teacherData)) {
        try {
            const response = await axiosPrivate.put('/teacher',data);
            setMessage(response.data.message);
            setTimeout(() => {
                setMessage('');
            },2000);
            handleCloseModal();
        } catch (error) {
            console.error('Error updating student:', error);
        }
    }else{
        handleCloseModal();
    }
  }

  function objectsAreEqual(obj1, obj2) {
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
      if (keys1.length !== keys2.length) {
        return false;
      }
      for (const key of keys1) {
        if (obj1[key] !== obj2[key]) {
          return false;
        }
      }
      return true;
  }      
    
  return (
    <div className={`fixed z-10 inset-0 overflow-y-auto`}>
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left" style={{ width:'80%'}}>
                <h3 className="text-3xl leading-6 font-medium text-gray-900 mb-4">Edit Information</h3>
                <form onSubmit={handleUpdate}>
                    <InputField
                        wrapperClassName="mt-2 flex flex-col md:flex-row justify-between "
                        label="Username"
                        labelClassName="pt-2 block text-justify text-xl font-medium text-gray-700"
                        divClassName="mt-1"
                        id="username"
                        name="username"
                        type="text"
                        value={data.username}
                        onChange={(e) => {setData((prevData) => ({...prevData, username: e.target.value}));}}
                        inputClassName="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    <InputField
                        wrapperClassName="mt-2 flex flex-col md:flex-row justify-between "
                        label="Name"
                        labelClassName="pt-2 block text-justify text-xl font-medium text-gray-700"
                        divClassName="mt-1"
                        id="name"
                        name="name"
                        type="text"
                        value={data.name}
                        onChange={(e) => {setData((prevData) => ({...prevData, name: e.target.value}));}}
                        inputClassName="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    <InputField
                        wrapperClassName="mt-2 flex flex-col md:flex-row justify-between "
                        label="Email"
                        labelClassName="pt-2 block text-justify text-xl font-medium text-gray-700"
                        divClassName="mt-1"
                        id="email"
                        name="email"
                        type="text"
                        value={data.email}
                        onChange={(e) => {setData((prevData) => ({...prevData, email: e.target.value}));}}
                        inputClassName="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    <InputField
                        wrapperClassName="mt-2 flex flex-col md:flex-row justify-between "
                        label="Phone"
                        labelClassName="pt-2 block text-justify text-xl font-medium text-gray-700"
                        divClassName="mt-1"
                        id="phone"
                        name="phone"
                        type="number"
                        value={data.phone}
                        onChange={(e) => {setData((prevData) => ({...prevData, phone: e.target.value}));}}
                        inputClassName="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    <InputField
                        wrapperClassName="mt-2 flex flex-col md:flex-row justify-between "
                        label="Qualification"
                        labelClassName="pt-2 block text-justify text-xl font-medium text-gray-700"
                        divClassName="mt-1"
                        id="qualification"
                        name="qualification"
                        type="text"
                        value={data.qualification}
                        onChange={(e) => {setData((prevData) => ({...prevData, qualification: e.target.value}));}}
                        inputClassName="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    <InputField
                        wrapperClassName="mt-2 flex flex-col md:flex-row justify-between "
                        label="Designation"
                        labelClassName="pt-2 block text-justify text-xl font-medium text-gray-700"
                        divClassName="mt-1"
                        id="designation"
                        name="designation"
                        type="text"
                        value={data.designation}
                        onChange={(e) => {setData((prevData) => ({...prevData, designation: e.target.value}));}}
                        inputClassName="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    <div className="pt-9 bg-gray-50 justify-center px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        <button onClick={handleCloseModal} type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                        Close
                        </button>
                        <button type="submit" className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                        Save Changes
                        </button>
                    </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditModal;
