import React, { useState, useEffect } from 'react';
import { BsTwitterX } from "react-icons/bs";
import { FaFacebookF, FaInstagram, FaLinkedin } from "react-icons/fa";

const OurServices = () => {
  return (
    <div className="container mx-auto px-4 py-6 md:px-6 lg:px-12 lg:py-12">
      <div className='flex flex-col items-center'>
        <h2 className="text-3xl text-center sm:text-4xl lg:text-5xl font-bold mb-4">Our <span className="text-blue-400 inline-block">Services</span></h2>
        <p className="text-center text-lg mb-6">
          Our services effectively help you communicate the comprehensive capabilities and benefits of your 'SMART Monitoring Attendance and Report' system, emphasizing its customization for Maulana Abul Kalam Azad University of Technology.
        </p>
      </div>
      <div className="flex flex-wrap box-border sm:mx-0 lg:mx-28">
        <div className="w-full md:w-1/3 p-4">
          <div className="flex flex-col items-center bg-gray-50 shadow-md rounded-lg p-6 transition duration-300 ease-in-out transform hover:scale-105">
            <img src="/images/s1.webp" alt="Service 1" className="h-20 object-cover mb-4 rounded-t-lg" />
            <h3 className="text-xl font-bold mb-2">ATTENDANCE</h3>
            <p className="text-center text-gray-600 text-base mb-4">
              Administrators have access to a live dashboard that displays real-time attendance data. This feature enables immediate identification of any discrepancies or attendance issues.
            </p>
            <a href="#" className="hover:text-blue-400 text-blue font-bold py-2 px-4 rounded">
              Read More
            </a>
          </div>
        </div>
        <div className="w-full md:w-1/3 p-4">
          <div className="flex flex-col items-center bg-gray-50 shadow-md rounded-lg p-6 transition duration-300 ease-in-out transform hover:scale-105">
            <img src="/images/s2.png" alt="Service 2" className=" h-20 object-cover mb-4 rounded-t-lg" />
            <h3 className="text-xl font-bold mb-2">REPORT</h3>
            <p className="text-center text-gray-600 text-base mb-4">
              Our system offers customizable reporting tools that allow users to generate detailed attendance reports based on various parameters, such as date range, department, or individual performance.
            </p>
            <a href="#" className="hover:text-blue-400 text-blue font-bold py-2 px-4 rounded">
              Read More
            </a>
          </div>
        </div>
        <div className="w-full md:w-1/3 p-4">
          <div className="flex flex-col items-center bg-gray-50 shadow-md rounded-lg p-6 transition duration-300 ease-in-out transform hover:scale-105">
            <img src="/images/s3.png" alt="Service 3" className=" h-20 object-cover mb-4 rounded-t-lg" />
            <h3 className="text-xl font-bold mb-2">ABSENCE MANAGEMENT</h3>
            <p className="text-center text-gray-600 text-base mb-4">
              We simplify leave management by allowing users to request leave directly through the system. Supervisors can approve or reject these requests with ease, and all absences are automatically tracked and recorded.
            </p>
            <a href="#" className="text-blue hover:text-blue-600 font-bold py-2 px-4">
              Read More
            </a>
          </div>
        </div>
      </div>
      <div className="text-center mt-6">
        <a href="#" className="bg-button-blue hover:bg-button-hover text-white font-bold py-2 px-4 rounded">
          View All
        </a>
      </div>
    </div>
  );
};

const About = () => {
  return (
    <div className="bg-custom-blue py-8 px-4 sm:px-6 lg:py-16 lg:px-8">
      <div className="max-w-max mx-auto">
        <div className="lg:text-center">
          <h2 className="text-3xl text-center sm:text-4xl lg:text-5xl text-white font-bold mb-4">
            About <span className="text-blue-400">Us</span>
          </h2>
          <p className="mt-2 text-center text-base sm:text-lg lg:text-xl text-white leading-8 tracking-tight">
            We are a group of enthusiastic and motivated students from Budge Budge Institute of Technology.
          </p>
          <div className="flex flex-col sm:flex-row mx-auto mt-6 sm:max-w-xl lg:max-w-full">
            <img
              src="/images/about-img.jpg"
              alt="BBIT Students"
              className="mt-8 sm:mt-0 h-auto sm:h-72 rounded-lg shadow-lg"
            />
            <div className="ml-0 sm:ml-4 mt-4 sm:mt-0">
              <p className="text-left mt-4 max-w-2xl text-lg text-white">
                <span className="text-2xl sm:text-3xl lg:text-4xl font-bold">We Are S.M.A.R.T</span>
                <br />
                Our mission is to create a supportive and engaging community for all students at BBIT. We believe that every student's journey through college should be not only academically enriching but also filled with memorable experiences and personal growth.
              </p>
              <p className="text-left mt-4 max-w-2xl text-lg text-white">
                We welcome all BBIT students to join us in our journey of personal and collective growth. By becoming a part of our community, you'll have the opportunity to meet like-minded individuals, develop leadership skills, and contribute to making BBIT an even better place for all students.
              </p>
              <div className="mt-6 text-left">
                <a href="#" className="px-6 inline-block py-2 bg-button-blue hover:bg-button-hover text-white font-medium transition duration-150 ease-in-out">
                  Read More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Navbar = ({active, setActive}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className=" p-4">
      <div className="mt-1 mr-4 flex justify-between items-center">
        <div className="ml-4 text-left text-white text-2xl font-bold">S.M.A.R.T</div>
        <div className="md:hidden">
          <button
            onClick={toggleMenu}
            className="text-white focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              ></path>
            </svg>
          </button>
        </div>
        <div className={`md:flex items-center ${isOpen ? 'block' : 'hidden'}`}>
          <a onClick={(e)=>setActive('home')} className={`block cursor-default md:inline-block mt-4 md:mt-0 md:ml-6 ${active == 'home'? 'text-button-blue':'text-white'}`}>
            HOME
          </a>
          <a onClick={(e)=>setActive('about')} className={`block cursor-default md:inline-block mt-4 md:mt-0 md:ml-6 ${active == 'about'? 'text-button-blue':'text-white'}`}>
            ABOUT
          </a>
          <a onClick={(e)=>setActive('services')} className={`block cursor-default md:inline-block mt-4 md:mt-0 md:ml-6 ${active == 'services'? 'text-button-blue':'text-white'}`}>
            SERVICES
          </a>
          <a onClick={(e)=>setActive('whyus')} className={`block cursor-default md:inline-block mt-4 md:mt-0 md:ml-6 ${active == 'whyus'? 'text-button-blue':'text-white'}`}>
            WHY US
          </a>
          <a onClick={(e)=>setActive('team')} className={`block cursor-default md:inline-block mt-4 md:mt-0 md:ml-6 ${active == 'team'? 'text-button-blue':'text-white'}`}>
            TEAM
          </a>
          <a href="/admin" className={`text-white block cursor-default md:inline-block mt-4 md:mt-0 md:ml-6`}>
            ADMIN LOGIN
          </a>
          <a href="/signin" className={`text-white block md:inline-block mt-4 md:mt-0 md:ml-6`}>
            FACULTY LOGIN
          </a>
        </div>
      </div>
    </nav>
  );
};

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const slides = [
    {
      id: 1,
      image: '/images/slider-img.png',
      heading: 'Customizable Reporting and Enhanced Analytics',
      content: 'For Comprehensive Reporting, the system should offer customizable reports that can be adjusted by date range, department, or individual, providing detailed insights into attendance patterns. Enhanced analytics tools can help identify trends, such as frequent absences or late arrivals, enabling proactive management.'
    },
    {
      id: 2,
      image: '/images/slider-img1.svg',
      heading: 'Integrated Leave and Absence Management',
      content: "Incorporating Leave and Absence Management within the system allows users to request leave directly, which can be approved or rejected by supervisors. This feature ensures that absences are tracked accurately and integrated with overall attendance records, providing a complete overview of each user's attendance."
    },
    {
      id: 3,
      image: '/images/slider-img2.png',
      heading: 'Streamlined User Interface for Easy Navigation',
      content: 'A User-Friendly Interface is vital for ensuring that all users, from administrators to employees, can navigate the system easily. A self-service portal allows users to view their attendance records, request corrections, and manage their profiles, enhancing user autonomy and satisfaction.'
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(interval);
  }, []);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
  };

  return (
    <div className="relative w-full overflow-hidden">
      <div
        className="flex transition-transform duration-1000 ease-in-out"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {slides.map((slide) => (
          <div key={slide.id} className="w-full flex-shrink-0">
            <div className="flex flex-col lg:flex-row items-center justify-center h-screen px-4 lg:px-32">
              <div className="lg:w-1/2 lg:mr-4 text-center lg:text-left">
                <h1 className="text-white text-3xl lg:text-5xl font-bold">{slide.heading}</h1>
                <p className="mt-3 text-white">{slide.content}</p>
                <div className="mt-6">
                  <a href="#" className="px-6 inline-block py-2 bg-button-blue hover:bg-button-hover text-white font-medium transition duration-150 ease-in-out">
                    Read More
                  </a>
                </div>
              </div>
              <div className="w-full lg:w-1/2 flex justify-center lg:justify-end mt-6 lg:mt-0">
                <img src={slide.image} className="w-full lg:w-2/3 h-auto" alt={slide.heading} />
              </div>
            </div>
          </div>
        ))}
      </div>
      <button
        onClick={prevSlide}
        className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full"
      >
        &#10094;
      </button>
      <button
        onClick={nextSlide}
        className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full"
      >
        &#10095;
      </button>
    </div>
  );
};

const WhyUsComponent = () => {
  const data = [
    {
      imageUrl: '/images/w1.png',
      heading: 'Customization',
      content: 'The solutions provided are custom-made to perfectly fit the distinct requirements of MAKAUT, guaranteing smooth integration with their current systems and workflows.',
    },
    {
      imageUrl: '/images/w2.png',
      heading: 'Efficiency',
      content: 'Our systems streamline the attendance-taking process and report generation, saving time and effort for faculty, staff, and administrators at MAKAUT.',
    },
    {
      imageUrl: '/images/w3.png',
      heading: 'Accuracy',
      content: 'By utilizing advanced technologies, such as SMART Monitoring, we ensure accurate attendance records and reliable report generation and improving data integrity.',
    },
    {
      imageUrl: '/images/w4.png',
      heading: 'Support',
      content: 'We provide ongoing support and assistance to ensure smooth implementation and operation of our solutions at MAKAUT, offering training, troubleshooting, and updates as needed.',
    },
  ];
  return (
    <div className="container mx-auto px-4">
      <h2 className="text-3xl text-center sm:text-4xl lg:text-5xl font-bold lg:mb-16 mt-4 mb-4">Why Choose <span className="text-blue-400 inline-block">Us</span></h2>
      <div className="lg:h-80 h-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {data.map((item, index) => (
          <div key={index} className="mb-4">
            <div className="flex flex-col items-center bg-white shadow-lg rounded-lg overflow-hidden">
              <div className="rounded-full h-24 w-24 flex items-center justify-center border-4 border-black">
                <img
                  src={item.imageUrl}
                  alt={item.heading}
                  className="h-14 w-14"
                />
              </div>
              <div className="p-4 text-center">
                <h2 className="text-xl font-semibold mb-2">{item.heading}</h2>
                <p className="text-gray-700">{item.content}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const OurTeam = () => {

  const teamMembers = [
    { name: 'Yusuf Alam', role: 'Project Head', imageUrl: 'images/team-1.jpg' },
    { name: 'Souvik Saha', role: 'Developer', imageUrl: 'images/team-2.jpg' },
    { name: 'Ayan Pramanik', role: 'Developer', imageUrl: 'images/team-3.jpg' },
    { name: 'Kazi Sayemah', role: 'Business Administrative', imageUrl: 'images/team-4.jpg' },
    { name: 'Saklin Mustak Laskar', role: 'Test Engineer', imageUrl: 'images/team-5.jpg' },
    { name: 'MD Sohel', role: 'Test Engineer', imageUrl: 'images/team-6.jpg' },
  ];

  return (
    <div className="bg-custom-blue py-8 px-4">
      <h2 className="text-white text-3xl text-center sm:text-4xl lg:text-5xl font-bold lg:mb-16 mt-4 mb-4">Our <span className="text-blue-400 inline-block">Team</span></h2>
      <div className="flex flex-wrap justify-center gap-8">
        {teamMembers.map((member, index) => (
          <div
            key={index}
            className="bg-team-card text-white flex-grow rounded-lg p-6 shadow-lg w-full sm:w-72 md:w-72 lg:w-72 flex flex-col items-center"
          >
            <div className="w-24 h-24 mb-4">
              <img
                src={member.imageUrl}
                alt={member.name}
                className="rounded-full w-full h-full object-cover"
              />
            </div>
            <h3 className="text-xl font-semibold">{member.name}</h3>
            <p className="text-blue-300">{member.role}</p>
            <div className="flex mt-4 space-x-3">
              <a href="#" className="text-white">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="#" className="text-white">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#" className="text-white">
                <i className="fab fa-linkedin-in"></i>
              </a>
              <a href="#" className="text-white">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="#" className="text-white">
                <i className="fab fa-youtube"></i>
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const Footer = () => {
  return (
    <footer className="bg-custom-blue text-white py-8 mt-auto">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/4 text-center md:text-left">
            <h2 className="text-2xl font-bold mb-4">Address</h2>
            <a className="hover:text-button-blue" href="https://www.google.com/maps/place/BBIT+(Administrative+Block)/@22.4589152,88.1698047,15z/data=!4m6!3m5!1s0x3a027d7db8e55555:0xbcbc30454678f84f!8m2!3d22.4589152!4d88.1698047!16s%2Fg%2F11ggs4zzv2?entry=ttu">
              <i className="fa fa-map-marker" aria-hidden="true"></i>
              <span className='ml-1'>Location<br />Nishchintapur, Budge Budge,<br /> Kolkata, West Bengal 700137</span>
            </a><br />
            <a className="hover:text-button-blue" href="tel:+918335950253">
              <i className="fa fa-phone" aria-hidden="true"></i>
              <span className="ml-1">
                Call +91 8335950253
              </span>
            </a><br />
            <a className="hover:text-button-blue" href="mailto:aasbbit2023@gmail.com">
              <i className="fa fa-envelope" aria-hidden="true"></i>
              <span className='ml-1'>
                aasbbit2023@gmail.com
              </span>
            </a>
          </div>
          <div className="w-full md:w-1/4 text-center md:text-left">
            <h2 className="text-2xl font-bold mb-4">Info</h2>
            <p>At BBIT, we organize a variety of events, workshops, and activities that cater to the diverse interests and needs of our fellow students. Whether you're looking for academic support, career guidance, or simply want to unwind and socialize, we have something for everyone.</p>
          </div>
          <div className="w-full md:w-1/4 text-center md:text-left">
            <h2 className="text-2xl font-bold mb-4 ml-6">Quick Links</h2>
            <ul className="list-unstyled text-xl">
              <li>
                <a href="#" className="text-white hover:text-button-blue ml-6">Home</a>
              </li>
              <li>
                <a href="#" className="text-white hover:text-button-blue ml-6">About Us</a>
              </li>
              <li>
                <a href="#" className="text-white hover:text-button-blue ml-6">Services</a>
              </li>
              <li>
                <a href="#" className="text-white hover:text-button-blue ml-6">Contact Us</a>
              </li>
            </ul>
          </div>
          <div className="w-full md:w-1/4 text-center md:text-left">
            <h2 className="text-2xl font-bold mb-4">Follow Us</h2>
            <ul className="list-unstyled flex flex-col items-center lg:items-start">
              <li className="flex flex-row items-center text-xl">
                <FaFacebookF />
                <a href="#" className="ml-1 text-white hover:text-button-blue">Facebook</a>
              </li>
              <li className="flex flex-row items-center text-xl">
                <BsTwitterX />
                <a href="#" className="ml-1 text-white hover:text-button-blue">Twitter</a>
              </li>
              <li className="flex flex-row items-center text-xl">
                <FaInstagram />
                <a href="#" className="ml-1 text-white hover:text-button-blue">Instagram</a>
              </li>
              <li className="flex flex-row items-center text-xl">
                <FaLinkedin />
                <a href="#" className="ml-1 text-white hover:text-button-blue">LinkedIn</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="bg-white py-4 text-center">
        <p className=" text-black">
          &copy; {new Date().getFullYear()} All Rights Reserved By S.M.A.R.T.
        </p>
      </div>
    </footer>
  );
};

export default function Home() {
  const [active, setActive] = useState('home');

  return (
    <div className="flex flex-col min-h-screen">
      <div className={`${active === 'home' ? 'lg:bg-carousal-image' : null} bg-carousal`}>
        <Navbar active={active} setActive={setActive} />
        {
          active === 'home' && <Carousel />
        }
      </div>
      <main className="flex-grow">
        {
          (active === 'home' || active === 'services') && <OurServices />
        }
        {
          (active === 'home' || active === 'about') && <About />
        }
        {
          (active === 'home' || active === 'whyus') && <WhyUsComponent />
        }
        {
          (active === 'home' || active === 'team') && <OurTeam />
        }
      </main>
      <Footer />
    </div>
  );
}

