import { useNavigate, useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { HiEye, HiEyeOff } from "react-icons/hi";
import InputField from "./components/InputField";
import { useState } from 'react';
import axios from '../../api/axios';
const LOGIN_URL = '/adminLogin';

export default function SignIn() {
  const [ showPassword, setShowPassword ] = useState(false);
  const [ username, setUsername ] = useState("");
  const [ password, setPassword ] = useState("");
  const [ error, setError ] = useState("");
  const { setAuth, setIsAuthenticated } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await axios.post(LOGIN_URL,
          { username:username, password:password },
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
          }
      )
      .then((response) => {
        
        const accessToken = response?.data?.accessToken;
        const roles =response?.data?.roles;
        setAuth({ roles, accessToken });
        setIsAuthenticated(true);
        navigate("/admin-dashboard", { state: { from: location }, replace: true });
      })
          
    } catch (err) {
        if (!err?.response) {
            setError('No Server Response');
        } else if (err.response?.status === 400) {
            setError('Missing Username or Password');
        } else if (err.response?.status === 401) {
            setError('Unauthorized');
        } else {
            setError('Login Failed');
        }
    }
  };
  
  const passwordInputType = showPassword ? "text" : "password";
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };


  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <h2 className="mb-5 text-center text-3xl font-extrabold text-gray-900">
            Admin Login
          </h2>
          <div className="text-red-500 flex justify-center"><div>{error}</div></div>
          <form className="space-y-6" onSubmit={handleLogin}>
            <div>
              <InputField
                label="Username"
                labelClassName="block text-xl font-medium text-gray-700"
                divClassName="mt-1"
                id="username"
                name="username"
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                inputClassName="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="Type your username"
                required={true}
              />
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-xl font-medium text-gray-700"
              >
                Password <span className="text-red-500">*</span>
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  id="password"
                  name="password"
                  type={passwordInputType}
                  autoComplete="current-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm pr-10"
                  placeholder="Type Your Password"
                />
                <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                  {showPassword ? (
                    <HiEyeOff
                      className="h-5 w-5 text-gray-400 cursor-pointer"
                      onClick={handleTogglePassword}
                    />
                  ) : (
                    <HiEye
                      className="h-5 w-5 text-gray-400 cursor-pointer"
                      onClick={handleTogglePassword}
                    />
                  )}
                </span>
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-l font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-300 ease-in-out transform hover:scale-105"
                >
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
