import React, { useState, useEffect } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import EditModal from './EditModal';
import Select from 'react-select'

export default function Routine() {
  const axiosPrivate = useAxiosPrivate();  
  const [ message, setMessage ] = useState("");
  const [ schedule, setSchedule ] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ subjectOptions, setSubjectOptions ] = useState();
  const [ selectedDay, setSelectedDay ] = useState();
  const [ selectedTimeSlot, setSelectedTimeSlot ] = useState();

  useEffect(()=> {
    const fetchData = async () => {
      try {
        const subject = await axiosPrivate.get('/subject');
        if (subject.status === 200) {
          setSubjectOptions(convertSubToOptions(subject.data))
        }
        const routine = await axiosPrivate.get('/routine');
        if (routine.status === 200) {
          setSchedule(routine.data)
          // console.log(routine.data)
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  },[message,axiosPrivate])
  const days = Object.keys(schedule);
  const timeslots = [
    '9:50-10:40',
    '10:40-11:30',
    '11:30-12:20',
    '12:20-1:10',
    '1:50-2:40',
    '2:40-3:30',
    '3:30-4:20',
    '4:20-5:10'
  ];
  const convertSubToOptions = (subArray) => {
    const optionsArray = subArray.map((sub) => {
      return {
        value: sub.code,
        label: `${sub.name}(${sub.code})`,
      };
    });
    return optionsArray;
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    // setSelectedSubject(null);
  };

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full border-collapse border border-gray-200">
        <thead className="bg-gray-100">
          <tr>
            <th scope="col" className="border border-gray-200 px-4 py-2 text-lg font-medium text-gray-600">
              Day
            </th>
            {timeslots.map((timeslot, index) => (
              <th key={index} scope="col" className="border text-center border-gray-200 px-4 py-2 text-lg font-medium text-gray-600">
                {timeslot}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white">
          {days.map((day, dayIndex) => (
            <tr key={dayIndex} className="hover:bg-gray-50">
              <td className="border border-gray-200 px-4 py-2 font-medium text-gray-800 text-2xl">
                {day}
              </td>
              {schedule[day].map((subject, subjectIndex) => (
                <td key={subjectIndex} onClick={(e)=>{setSelectedTimeSlot(timeslots[subjectIndex]);setSelectedDay(day);setIsModalOpen(true)}} className="border text-center border-gray-200 text-gray-700 text-xl" style={{ height: `calc(100vh / ${days.length + 1})` }}>
                  {
                    subject !== "" &&
                    <p>{subject.split('_')[0]+" "+subject.split('_')[1]+" Year"}<br/>{"Section "+subject.split('_')[2]}<br/>{subject.split('_')[3]}</p>
                  }                  
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {/* <Select onChange={(e)=>console.log(e.value)} options={subjectOptions} /> */}
      {
        isModalOpen && <EditModal selectedTimeSlot={selectedTimeSlot} selectedDay={selectedDay} subjectOptions={subjectOptions} setMessage={setMessage} handleCloseModal={handleCloseModal} />
      }
      {
        message !== '' && 
        <div className="fixed top-0 right-0 mr-4 mt-4 bg-green-500 text-xl text-white py-2 px-4 rounded-md shadow-md z-50">
        {message}
        </div>
      }
    </div>
  );
};
