import React, { useState } from 'react';
import Select from 'react-select';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';

const EditModal = ({subjectOptions, selectedTimeSlot, selectedDay, setMessage, handleCloseModal }) => {
    const [ data, setData ] = useState();
    const [ department, setDepartment ] = useState('');
    const [ year, setYear ] = useState('');
    const [ section, setSection ] = useState('');
    const [ subject, setSubject ] = useState('');
    const axiosPrivate = useAxiosPrivate();

    const handleUpdate = async (e) => {
        e.preventDefault();
        if( department === '' || year === '' || subject === ''){
            console.error('all fields are required');
        }else{
            const data ={
                section:`${department}_${year}_${section}_${subject}`,
                day:selectedDay,
                timeslot: selectedTimeSlot
            };
            try {
                const response = await axiosPrivate.put('/routine', data);
                setMessage(response.data);
                setTimeout(() => {
                  setMessage('');
                }, 2000);
                handleCloseModal();
            } catch (error) {
                console.error('Error updating routine:', error);
            }
        }
    };

    const departments = [
        { value: 'CSE', label: 'CSE' },
        { value: 'IT', label: 'IT' },
        { value: 'CIVIL', label: 'CIVIL' },
        { value: 'ECE', label: 'ECE' },
        { value: 'MECHANICAL', label: 'MECHANICAL' },
    ];

    const years = [
        { value: '1', label: 'First Year' },
        { value: '2', label: 'Second Year' },
        { value: '3', label: 'Third Year' },
        { value: '4', label: 'Fourth Year' },
    ];

    const sections = [
        { value: 'A', label: 'A' },
        { value: 'B', label: 'B' },
        { value: 'C', label: 'C' },
        { value: '' , label:'none'}
    ];

    return (
        <div className={`fixed z-10 inset-0 overflow-y-auto`}>
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4" style={{width: "-webkit-fill-available"}}>
                        <h3 className="text-3xl leading-6 text-center font-medium text-gray-900 mb-4">Routine Edit</h3>
                        <h3 className="text-3xl leading-6 text-center font-medium text-gray-900 mb-4">{selectedDay}</h3>
                        <h3 className="text-3xl leading-6 text-center font-medium text-gray-900 mb-4">{selectedTimeSlot}</h3>
                        <form className="w-max lg:w-full text-xl" onSubmit={handleUpdate}>
                        <div className="mt-2 flex flex-col md:flex-row justify-center">
                            {/* <label className="pt-2 block text-justify text-xl font-medium text-gray-700" htmlFor="subject">Subject</label> */}
                            <Select
                            id="subject"
                            name="subject"
                            placeholder="Select the Subject"
                            options={subjectOptions}
                            //   value={subjects.find(sub => sub.value === data.subject)}
                            onChange={(e) => setSubject(e.value)}
                            className="mt-2 w-full"
                            />
                        </div>
                        <div className="mt-2 flex flex-col md:flex-row justify-center">
                            <Select
                            id="department"
                            name="department"
                            placeholder="Select the department"
                            options={departments}
                            //   value={departments.find(dep => dep.value === data.department)}
                            onChange={(e) => setDepartment(e.value)}
                            className="mt-2 w-full"
                            />
                        </div>
                        <div className="mt-2 flex flex-col md:flex-row justify-center">
                            <Select
                            id="year"
                            name="year"
                            placeholder="Select the Year"
                            options={years}
                            //   value={years.find(year => year.value === data.year)}
                            onChange={(e) => setYear(e.value)}
                            className="mt-2 w-full"
                            />
                        </div>
                        <div className="mt-2 flex flex-col md:flex-row justify-center">
                            <Select
                            id="section"
                            name="section"
                            placeholder="Select the Section"
                            options={sections}
                            //   value={sections.find(sec => sec.value === data.section)}
                            onChange={(e) => setSection(e.value)}
                            className="mt-2 w-full"
                            />
                        </div>
                        <div className="pt-9 bg-gray-50 justify-center px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                            <button onClick={handleCloseModal} type="button" className="w-full text-lg inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto">
                            Close
                            </button>
                            <button type="submit" className="mt-3 w-full text-lg inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:mt-0 sm:ml-3 sm:w-auto">
                            Save Changes
                            </button>
                        </div>
                        </form>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    );
};

export default EditModal;
