import React,{useState,useEffect} from 'react';
import { Link } from 'react-router-dom';
import Modal from './Modal';
import { RiDeleteBinLine } from 'react-icons/ri';
import DataTable from 'react-data-table-component';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import ConfirmAlert from '../../../components/ConfirmAlert';

export default function ManageSection() {
  const [isModalOpen,setIsModalOpen] = useState(false);
  const [isConfirmOpen,setIsConfirmOpen] = useState(false);
  const [selectedSection,setSelectedSection] = useState(null);
  const [selectedSectionData,setSelectedSectionData] = useState(null);
  const [page,setPage] = useState('allSection');
  const [message,setMessage] = useState('');
  const axiosPrivate = useAxiosPrivate();
  const [sections, setSections] = useState();

  const customStyles = {
    headCells: {
      style: {
        fontSize: '20px'
      },
    },
    cells: {
      style: {
        fontSize: '15px'
      },
    },
  };

  useEffect(()=> {
    const fetchData = async () => {
      try {
        const response = await axiosPrivate.get('/section');
        if (response.status === 200) {
          setSections(response.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  },[message,axiosPrivate])

  const handleClose = () => {
    setIsConfirmOpen(false);
    setSelectedSection(null);
  }
  const handleConfirm = async() => {
    let url =`/section/${selectedSection.department}_${selectedSection.year}_${selectedSection.section}`;
    try {
      const response = await axiosPrivate.delete(url);
      if (response.status === 200) {
        setMessage(response.data);
        setTimeout(() => {
          setMessage('');
        }, 5000);
        handleClose();
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const AllSection = () => {
    const handleSectionClick = async(section) => {
      setPage('singleSection');
      setSelectedSection(section);
      let url =`/section/${section.department}_${section.year}_${section.section}`
      try {
        const response = await axiosPrivate.get(url);
        if (response.status === 200) {
          setSelectedSectionData(response.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    return(
      <>
        <div className="flex flex-row justify-between mb-6">
          <h1 className="text-3xl font-bold ">Manage Sections</h1>
          <Link
            to={'/admin-dashboard'}
            className="inline-block bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md shadow-md transition duration-300 ease-in-out"
          >
            Back to dashboard
          </Link>
        </div>
        <div className="flex flex-wrap items-center mb-4">
          {/* Filter by Department */}
          <div className="flex items-center mr-4 mb-2">
            <div className="relative">
              <select
                name="department"
                // value={filter.sex}
                // onChange={(e) => setFilter(prevFilter => ({ ...prevFilter, sex: e.target.value }))}
                className="border p-2 rounded-md appearance-none bg-white focus:outline-none focus:border-blue-500"
              >
                <option value="">Department</option>
                <option value="CSE">CSE</option>
                <option value="IT">IT</option>
                <option value="ECE">ECE</option>
                <option value="CIVIL">CIVIL</option>
                <option value="MECHANICAL">MECHANICAL</option>
              </select>
            </div>
          </div>
          {/* Filter by Year */}
          <div className="flex items-center mr-4 mb-2">
            <div className="relative">
              <select
                name="year"
                // value={filter.sex}
                // onChange={(e) => setFilter(prevFilter => ({ ...prevFilter, sex: e.target.value }))}
                className="border p-2 rounded-md appearance-none bg-white focus:outline-none focus:border-blue-500"
              >
                <option value="">Year</option>
                <option value="1">1 year</option>
                <option value="2">2 year</option>
                <option value="3">3 year</option>
                <option value="4">4 year</option>
              </select>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap gap-4">
          {/* Card for adding a new section */}
          <div
            className="flex justify-center items-center w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-4 cursor-pointer border border-dashed border-blue-500 rounded-md text-center shadow-md transition duration-300 hover:shadow-lg hover:bg-blue-100 transform hover:-translate-y-1 hover:scale-105"
            onClick={(e)=>setIsModalOpen(true)}
            >
            <p className="text-3xl text-blue-500">Add New Section</p>
          </div>    
          {/* Cards for displaying existing data */}
          {sections?.map((item, index) => (
            <div key={index} className="relative w-full h-auto sm:w-1/2 md:w-1/3 lg:w-1/4 p-4 cursor-pointer border border-blue-500 rounded-md shadow-md transition duration-300 hover:shadow-lg hover:bg-blue-100 transform hover:-translate-y-1 hover:scale-105 group">
              <div className='flex flex-col items-center' onClick={(e) => handleSectionClick(item)}>
                <p className="text-2xl font-bold">{item.department}</p>
                <p className="text-2xl font-bold">{item.year + ' year'}</p>
                <p className="text-2xl font-bold">{item.section}</p>

                {/* Delete icon */}
              </div>
              <div onClick={(e)=>{setSelectedSection(item);setIsConfirmOpen(true)}} className="absolute top-0 right-0 mt-1 mr-1 opacity-0 group-hover:opacity-100 transition-opacity">
                <RiDeleteBinLine className="text-red-500 text-2xl cursor-pointer" />
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }

  const SingleSection = () => {
    const [isTextAreaOpen, setIsTextAreaOpen] = useState(false);
    const [textAreaValue, setTextAreaValue] = useState('');

    const toggleTextArea = () => {
      setIsTextAreaOpen(!isTextAreaOpen);
      setTextAreaValue('');
    };

    const handleTextAreaChange = (event) => {
      setTextAreaValue(event.target.value);
    };

    const handleSubmit = () => {
      console.log('Submitted:', textAreaValue);
      setTextAreaValue('');
      setIsTextAreaOpen(false);
    };

    const handleRemoveClick = async(row) => {
      setSelectedSectionData((prevData)=>{
        return prevData.filter(item => item.roll !== row.roll);
      })
      let url =`/section/${row.roll}`
      console.log(url)
      try {
        const response = await axiosPrivate.delete(url);
        if (response.status === 200) {
          setSelectedSectionData(response.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    const columns = [
      {
        name: 'Roll',
        selector: row => row.roll,
        sortable:true
      },
      {
        name: 'Name',
        selector: row => row.name,
        sortable:true
      },
      {
        name: 'Sex',
        selector: row => row.sex,
        sortable:true
      },
      {
        name: 'Email',
        selector: row => row.mail,
      },
      {
        name: 'Phone',
        selector: row => row.phone,
      },
      {
        name: 'Remove',
        cell:(row) => {
          return <button onClick={() => handleRemoveClick(row)} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:scale-105">
                  Remove
                </button>
        },
      }
    ];

    return(
      <>
        <div className="flex flex-row justify-between mb-6">
          <h1 className="text-3xl font-bold ">{`${selectedSection?.year} Year ${selectedSection.department} ${selectedSection.section}`}</h1>
          <button
            onClick={(e)=>{setPage('allSection');setSelectedSectionData(null)}}
            className="inline-block bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md shadow-md transition duration-300 ease-in-out">
            Back to Sections
          </button>
        </div>
        {/* Add students section */}
        <div>
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={toggleTextArea}>
            Add Students
          </button>
          {isTextAreaOpen && (
            <div className="mt-4">
              <textarea
                className="border border-gray-400 rounded p-2 w-full"
                value={textAreaValue}
                onChange={handleTextAreaChange}
                placeholder="Enter the rolls of the students separated by comma to add students in this section.."
              ></textarea>
              <button className="mt-2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" onClick={handleSubmit}>
                Add
              </button>
            </div>
          )}
        </div>
        {/* Table section */}
        <div className="overflow-x-auto">
          {
            selectedSectionData &&
            <DataTable
              className="dataTable text-xl"
              columns={columns}
              data={selectedSectionData}
              pagination
              paginationPerPage={10}
              paginationRowsPerPageOptions={[10, 50, 100]}
              customStyles={customStyles}
              highlightOnHover
            />
          }
        </div>
      </>
    );
  }

  return (
    <div className="container mx-auto p-4">
      {
        page === 'allSection' &&
        <AllSection/>
      }
      {
        page === 'singleSection' &&
        <SingleSection/>
      }
      {
        isConfirmOpen && <ConfirmAlert message={`Do you want to delete the section <br> ${selectedSection.department} ${selectedSection.year} year ${selectedSection.section}`} onClose={handleClose} onConfirm={handleConfirm} />
      }
      {
        isModalOpen &&
        <Modal onClose={(e)=>setIsModalOpen(false)} setMessage={setMessage}/>
      }
      {
        message !== '' && 
        <div className="fixed top-0 right-0 mr-4 mt-4 bg-green-500 text-xl text-white py-2 px-4 rounded-md shadow-md z-50">
        {message}
        </div>
      }
    </div>

  );
}
