import React, { useState } from 'react';

export default function ConfirmAlert({ message, onConfirm, onClose }) {

  const handleConfirm = () => {
    onConfirm();
  };

  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
        <div className="bg-white rounded-lg p-8 max-w-md w-full shadow-md">
          <p className="text-xl font-semibold mb-4" dangerouslySetInnerHTML={{ __html: message }}></p>
          <div className="flex justify-center">
            <button
              onClick={handleConfirm}
              className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition duration-300 ease-in-out"
            >
              Confirm
            </button>
            <button
              onClick={onClose}
              className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md ml-4 hover:bg-gray-400 transition duration-300 ease-in-out"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

