// NotFound.js
import React from 'react';

export default function PageNotFound() {
  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <div className="max-w-md mx-auto text-center">
        <h1 className="text-6xl font-bold text-gray-800">404</h1>
        <p className="mt-4 text-xl text-gray-600">Page Not Found</p>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="mt-8 mx-auto w-16 h-16 animate-bounce">
          <path fillRule="evenodd" d="M8.293 7.293a1 1 0 011.414 0L10 8.586l.293-.293a1 1 0 011.414 1.414l-.293.293.293.293a1 1 0 01-1.414 1.414L10 11.414l-.293.293a1 1 0 01-1.414-1.414l.293-.293-.293-.293a1 1 0 010-1.414zM9 3a7 7 0 110 14A7 7 0 019 3zm0 1a6 6 0 100 12A6 6 0 009 4z" clipRule="evenodd" />
        </svg>
        <p className="mt-8 text-gray-600">Oops! The page you are looking for does not exist.</p>
        <a href="/" className="block mt-8 px-4 py-2 bg-indigo-600 hover:bg-indigo-700 text-white font-semibold rounded-lg shadow-md transition duration-300 ease-in-out">Go Back Home</a>
      </div>
    </div>
  );
}


