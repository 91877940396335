import React, { useState } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const LoadStudentsData = (props) => {
    const [ fileContent, setFileContent ] = useState();
    const axiosPrivate = useAxiosPrivate();
    const parseCsvData = (csvData) => {
        const rows = csvData.trim().split("\n");
        const headers = rows.shift().split(",");
        const parsedData = rows.map((row) => {
            const rowData = row.split(",");
            return headers.reduce((acc, header, index) => {
                acc[header] = rowData[index];
                return acc;
            }, {});
        });
        return parsedData;
    };

    const sendDataToBackend = async (data) => {
        console.log(props.url)
        try {
            const response = await axiosPrivate.post(props.url, data);
            if (response.status === 200) {
                console.log(response.data.message);
            } else {
                console.error("Failed to upload CSV data");
            }
        } catch (error) {
            console.error("Error uploading CSV data:", error);
        }
    };

    const handleFileLoad = (event) => {
        console.log(props.url)
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const content = e.target.result;
                setFileContent(content);
                sendDataToBackend(parseCsvData(content));
            };
            reader.readAsText(file);
        }
    };

    const handleFileUpload = () => {
        if (fileContent) {
            sendDataToBackend(parseCsvData(''));
        }
    };

    return (
        <div className="max-w-lg mx-auto mt-8 p-8 bg-white rounded-lg shadow-lg">
            <h2 className="text-2xl font-semibold mb-4">{props.heading}</h2>
            <div className="mb-4">
                <input
                    type="file"
                    accept=".csv"
                    className="hidden"
                    id="csvFileInput"
                    onChange={handleFileLoad}
                    value=""
                />
                <label
                    htmlFor="csvFileInput"
                    className="cursor-pointer bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg transition duration-300"
                >
                    Choose CSV File
                </label>
            </div>
            <button
                className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg transition duration-300"
                onClick={handleFileUpload}
            >
                Upload CSV
            </button>
        </div>
    );
};

export default LoadStudentsData;
