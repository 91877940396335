import React, { useState } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';

const Modal = ({ onClose, setMessage }) => {
  const axiosPrivate = useAxiosPrivate();
  const [error,setError] = useState('');
  const [year, setYear] = useState('');
  const [department, setDepartment] = useState('');
  const [section, setSection] = useState('');
  const [addStudents, setAddStudents] = useState(false);
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');

  const handleAddStudentsChange = (e) => {
    setAddStudents(e.target.checked);
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    if(year === '') setError('Year Cannot Be Empty');
    if(department === '') setError('Department Cannot Be Empty');
    if(section === '') setError('Section Cannot Be Empty');
    if(addStudents && (from === '' || to === '')){
      setError('To add students both From and To roll number should be set')
    }
    try {
      const response = await axiosPrivate.post('/section',
        { year:year, department:department, section:section, addStudents:addStudents, from:from, to:to }
      )
      if (response.status === 200) {
        setMessage(response.data);
        setTimeout(() => {
          setMessage('');
        }, 5000);
        onClose();
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left" style={{ width: '80%' }}>
                <h3 className=" text-center text-3xl leading-6 font-medium text-gray-900 mb-4">Add new Section</h3>
                <div className="text-red-500 flex justify-center"><div>{error}</div></div>
                <form onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <label className="block text-justify text-gray-700 text-l font-bold mb-2" htmlFor="year">
                      Year<span className="text-red-500">*</span>
                    </label>
                    <select
                      id="year"
                      className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500 bg-white"
                      value={year}
                      onChange={(e) => setYear(e.target.value)}
                    >
                      <option value="">Select Year</option>
                      <option value="1">1 year</option>
                      <option value="2">2 year</option>
                      <option value="3">3 year</option>
                      <option value="4">4 year</option>
                    </select>
                  </div>
                  <div className="mb-4">
                    <label className="block text-justify text-gray-700 text-l font-bold mb-2" htmlFor="department">
                      Department<span className="text-red-500">*</span>
                    </label>
                    <select
                      id="department"
                      className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500 bg-white"
                      value={department}
                      onChange={(e) => setDepartment(e.target.value)}
                    >
                      <option value="">Select Department</option>
                      <option value="CSE">CSE</option>
                      <option value="IT">IT</option>
                      <option value="CIVIL">CIVIL</option>
                      <option value="ECE">ECE</option>
                      <option value="MECHANICAL">MECHANICAL</option>
                    </select>
                  </div>
                  <div className="mb-4">
                    <label className="block text-justify text-gray-700 text-l font-bold mb-2" htmlFor="section">
                      Section<span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      id="section"
                      className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                      placeholder="Enter Section"
                      value={section}
                      onChange={(e) => setSection(e.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <input
                      type="checkbox"
                      id="addStudents"
                      checked={addStudents}
                      onChange={handleAddStudentsChange}
                      className="mr-2"
                    />
                    <label className="text-gray-700 text-l" htmlFor="addStudents">
                      Add Students
                    </label>
                  </div>
                  {addStudents && (
                    <div className="mb-4">
                      <label className="block text-justify text-gray-700 text-l font-bold mb-2" htmlFor="from">
                        From<span className="text-red-500">*</span>
                      </label>
                      <input
                        type="number"
                        id="from"
                        placeholder="Enter Starting Roll Of The Section"
                        className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                        value={from}
                        onChange={(e) => setFrom(e.target.value)}
                      />
                    </div>
                  )}
                  {addStudents && (
                    <div className="mb-4">
                      <label className="block text-justify text-gray-700 text-l font-bold mb-2" htmlFor="to">
                        To<span className="text-red-500">*</span>
                      </label>
                      <input
                        type="number"
                        id="to"
                        placeholder="Enter Ending Roll Of The Section"
                        className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                        value={to}
                        onChange={(e) => setTo(e.target.value)}
                      />
                    </div>
                  )}
                  <div className="pt-9 justify-center px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button
                      onClick={onClose}
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    >
                      Add Section
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
