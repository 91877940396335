import React from 'react'

export default function InputField(props) {
  return (
    <div className={props.wrapperClassName} >
        <label htmlFor={props.id} className={props.labelClassName} >
        {props.label}{props.required && <span className="text-red-500">*</span> }
        </label>
        <div className={props.divClassName}>
            <input
                id={props.id}
                name={props.name}
                type={props.type}
                value={props.value}
                onChange={(e) => { props.onChange?.(e) }}
                onBlur={(e) => { props.onBlur?.(e) }}
                className={props.inputClassName}
                placeholder={props.placeholder}
                required={props.required ? true : undefined}
            />
        </div>
    </div>
  )
}
