import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function TeacherDashboard() {

    const logoutHandler = () => {
        
    }

    const Timer = () => {
        const [currentTime, setCurrentTime] = useState(new Date());
      
        useEffect(() => {
          const timer = setInterval(() => {
            setCurrentTime(new Date());
          }, 1000);
          return () => clearInterval(timer);
        }, []);
      
        const formatTime = (time) => {
          return time.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          });
        };
      
        return (
            <h6>Current Time: {formatTime(currentTime)}</h6>
        );
      };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <h2 className="mb-5 text-center text-3xl font-extrabold text-gray-900">
                    Teacher Dashboard
                </h2>
                <div className="flex justify-between">
                    <h6>Username</h6>
                    <Timer/>
                </div>
                <hr className="w-full h-1 my-4 bg-gray-200 border-0 dark:bg-gray-700"></hr>
                <div className="flex flex-col gap-4">
                    <Link to="/teacher-dashboard/routine" className="bg-blue-500 text-white py-4 px-8 rounded-lg text-xl font-semibold hover:bg-blue-600 transition duration-300">
                        Create Routine
                    </Link>
                    <Link to="/teacher-dashboard/class" className="bg-blue-500 text-white py-4 px-8 rounded-lg text-xl font-semibold hover:bg-blue-600 transition duration-300">
                        Take Scheduled Class
                    </Link>
                    <Link to="/teacher-dashboard/class" className="bg-blue-500 text-white py-4 px-8 rounded-lg text-xl font-semibold hover:bg-blue-600 transition duration-300">
                        Take New Class
                    </Link>
                    <Link to="/teacher-dashboard/report" className="bg-blue-500 text-white py-4 px-8 rounded-lg text-xl font-semibold hover:bg-blue-600 transition duration-300">
                        Generate Report
                    </Link>
                </div>
                <div onClick={logoutHandler} className=" mt-20 flex justify-center hover:cursor-pointer bg-red-500 text-white py-4 px-8 rounded-lg text-xl font-semibold hover:bg-red-600 transition duration-300">
                    Logout
                </div>

            </div>            
        </div>
    </div>
  )
}
