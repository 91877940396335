import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useLogout from '../../hooks/useLogout';

function AdminDashboard() {

    const navigate = useNavigate();
    const logout = useLogout();

    const logoutHandler = async () => {
        await logout();
        navigate('/signin');
    }
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <h2 className="mb-5 text-center text-3xl font-extrabold text-gray-900">
                    Online Attendance System<br/>Admin Dashboard
                </h2>
                <div className="flex flex-col gap-4">
                    <Link to="/admin-dashboard/verify-registration" className="bg-blue-500 text-white py-4 px-8 rounded-lg text-xl font-semibold hover:bg-blue-600 transition duration-300">
                        Verify Registration
                    </Link>
                    <Link to="/admin-dashboard/view-teachers-data" className="bg-blue-500 text-white py-4 px-8 rounded-lg text-xl font-semibold hover:bg-blue-600 transition duration-300">
                        View Teachers Data
                    </Link>
                    <Link to="/admin-dashboard/view-students-data" className="bg-blue-500 text-white py-4 px-8 rounded-lg text-xl font-semibold hover:bg-blue-600 transition duration-300">
                        View Students Data
                    </Link>
                    <Link to="/admin-dashboard/view-subjects-data" className="bg-blue-500 text-white py-4 px-8 rounded-lg text-xl font-semibold hover:bg-blue-600 transition duration-300">
                        View Subject Data
                    </Link>
                    <Link to="/admin-dashboard/load-data" className="bg-blue-500 text-white py-4 px-8 rounded-lg text-xl font-semibold hover:bg-blue-600 transition duration-300">
                        Load Data
                    </Link>
                    <Link to="/admin-dashboard/manage-sections" className="bg-blue-500 text-white py-4 px-8 rounded-lg text-xl font-semibold hover:bg-blue-600 transition duration-300">
                        Manage sections
                    </Link>
                </div>
                <div onClick={logoutHandler} className=" mt-20 flex justify-center hover:cursor-pointer bg-red-500 text-white py-4 px-8 rounded-lg text-xl font-semibold hover:bg-red-600 transition duration-300">
                    Logout
                </div>

            </div>            
        </div>
    </div>
  );
}

export default AdminDashboard;
