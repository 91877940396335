import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
// import './ThankYouPage.css'; // Import CSS file for styles

const ThankYouPage = () => {
  const [showMessage, setShowMessage] = useState(false);

  const handleShowMessage = () => {
    setShowMessage(!showMessage);
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="max-w-md w-full p-8 bg-white rounded-lg shadow-md text-center">
        <FontAwesomeIcon icon={faCheckCircle} className="text-green-500 text-4xl mb-4" />

        <h1 className="text-2xl font-bold mb-4">Thank you for registering as a Teacher!</h1>

        <p className="text-gray-600 mb-4">
          Please let the admin verify your account. We will inform you once your account gets verified.
        </p>

        {/* Button to show/hide message */}
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300 ease-in-out"
          onClick={handleShowMessage}
        >
          {showMessage ? 'Hide Message' : 'Show Message'}
        </button>

        {/* Animated message */}
        {showMessage && (
          <div className="message bg-blue-100 p-4 mt-4 rounded-md">
            <p className="text-blue-800">Your account verification is pending...</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ThankYouPage;
// 